import React from 'react';
import {
  FormControlLabel,
  TextField,
} from '@material-ui/core';

// import useStyles from './styles';

export default function RatingsField(props) {
  const { value, tableMeta, updateValue } = props;
  const { score, label } = value;
  return <span>{label}</span>;
}
