import Api from './Api';

export default class BrandsAPIs extends Api {
  readAllBrands(accessToken) {
    return this.instance.post('brands-list', {}, this.constructor.addAuthorizationHeader(accessToken));
  }

  readOneBrand(accessToken, id) {
    return this.instance.get(`brands-homepage?brand=${id}`, this.constructor.addAuthorizationHeader(accessToken));
  }

  getHistoryBrands(accessToken, date) {
    console.log(`brands-history?start=${date.start}&end=${date.end}`)
    return this.instance.get(`brands-history?start=${date.start}&end=${date.end}`, this.constructor.addAuthorizationHeader(accessToken));
  }
}
