/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import RatingsDots from '../../components/RatingsDots';
import { calculateRatingDifference, getDots } from './ratingHelper';
//  Styles
import useStyles from './styles';

const createColumnns = (updated, deleted, added) => {
  const updatedArray = [];
  const addedArray = [];
  const deletedArray = [];
  if (updated.length > 0) {
    updated.map((brand) => {
      const {
        name,
        label,
        animal,
        environment,
        labour,
        prev_rating,
        effective_date: date,
      } = brand;
      return updatedArray.push({
        action: 'Updated',
        brandName: `${name}${(name !== prev_rating.name) ? ` (formerly ${prev_rating.name})` : ''}`,
        label: [label, prev_rating.label],
        animal: [animal, prev_rating.animal],
        environment: [environment, prev_rating.environment],
        labour: [labour, prev_rating.labour],
        date: moment(date).format("DD MMM 'YY"),
      });
    });
  }
  if (added.length > 0) {
    added.map((brand) => {
      const {
        name, label, animal, environment, labour, effective_date: date,
      } = brand;
      return addedArray.push({
        action: 'New',
        brandName: name,
        label: [label],
        animal: [animal],
        environment: [environment],
        labour: [labour],
        date: moment(date).format("DD MMM 'YY"),
      });
    });
  }
  if (deleted.length > 0) {
    deleted.map((brand) => {
      const {
        name, animal, environment, label, labour, effective_date: date,
      } = brand;
      return deletedArray.push({
        action: 'Removed',
        brandName: name,
        labour: [labour],
        label: [label],
        animal: [animal],
        environment: [environment],
        date: moment(date).format("DD MMM 'YY"),
      });
    });
  }

  return updatedArray.reverse().concat(addedArray.reverse()).concat(deletedArray.reverse());
};

const options = {
  downloadOptions: {
    filename: `goy-exported-${moment().format('DD-MMM-Y')}.csv`,
    separator: ',',
    filterOptions: {
      useDisplayedRowsOnly: true,
      useDisplayedColumnsOnly: true,
    },
  },
  selectableRows: 'none',
  searchOpen: false,
  print: false,
  download: true,
  rowsPerPageOptions: [10, 20, 50],
};

export default function HistoryTable({ data }) {
  const classes = useStyles();

  const { updated, deleted, added } = data;

  const getTrend = (value) => {
    if (value === 'DOWNWARDS') return <ArrowDownwardRoundedIcon className={classes[`arrow${value}`]} />;
    if (value === 'UPWARDS') return <ArrowUpwardRoundedIcon className={classes[`arrow${value}`]} />;
    return null;
  };

  return (
    <MUIDataTable
      title={false}
      data={createColumnns(updated, deleted, added)}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            searchable: false,
            filter: false,
            display: 'excluded',
          },
        },
        {
          name: 'brandName',
          label: 'Brand Name',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: false,
          },
        },
        {
          name: 'action',
          label: 'Action',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: false,
            customBodyRender: (value) => <p className={classes[value.toLowerCase()]}>{value}</p>,
          },
        },
        {
          name: 'date',
          label: 'Date',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: false,
          },
        },
        {
          name: 'label',
          label: 'Overall',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: false,
            customBodyRender: (value) => {
              const [rating, prevRating] = value;
              const delta = calculateRatingDifference(prevRating, rating);
              return (
                <>
                  <RatingsDots value={getDots(rating)} />
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Typography variant="body1">
                      <span>{rating}</span>
                    </Typography>
                    { prevRating && (
                    <div style={{ paddingLeft: 8 }}>
                      { getTrend(delta) }
                    </div>
                    )}
                  </div>
                </>
              );
            },
          },
        },
        {
          name: 'environment',
          label: 'Environment',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: false,
            filterType: 'dropdown',
            customBodyRender: (value) => {
              const [rating, prevRating] = value;
              const delta = calculateRatingDifference(prevRating, rating);
              return (
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="body1">
                    <span>{rating}</span>
                  </Typography>
                  <div style={{ paddingLeft: 8 }}>
                    { getTrend(delta) }
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: 'labour',
          label: 'Labour',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: false,
            customBodyRender: (value) => {
              const [rating, prevRating] = value;
              const delta = calculateRatingDifference(prevRating, rating);
              return (
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="body1">
                    <span>{rating}</span>
                  </Typography>
                  <div style={{ paddingLeft: 8 }}>
                    { getTrend(delta) }
                  </div>
                </div>
              );
            },
          },
        },
        {
          name: 'animal',
          label: 'Animal',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: false,
            customBodyRender: (value) => {
              const [rating, prevRating] = value;
              const delta = calculateRatingDifference(prevRating, rating);
              return (
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Typography variant="body1">
                    <span>{rating}</span>
                  </Typography>
                  <div style={{ paddingLeft: 8 }}>
                    { getTrend(delta) }
                  </div>
                </div>
              );
            },
          },
        },
      ]}
      options={options}
    />
  );
}

HistoryTable.propTypes = {
  data: PropTypes.shape({
    added: PropTypes.shape([]).isRequired,
    updated: PropTypes.shape([]).isRequired,
    deleted: PropTypes.shape([]).isRequired,
  }).isRequired,
};
