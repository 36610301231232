import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
} from '@material-ui/core';
import moment from 'moment';
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

// styles
import useStyles from './styles';
// components
import { Badge, Typography } from '../Wrappers';
import Notification from '../Notification/Notification';
import UserAvatar from '../UserAvatar/UserAvatar';
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { useUserDispatch, signOut, useUserState } from '../../context/UserContext';
import { BrandsAPIsSingleton as API } from '../../modules/Api';

import logo from '../../assets/media/logo_symbol_goodonyou_white.png';
import wordmark from '../../assets/media/logo_wordmark_goodonyou_white.png';

const messages = [
  {
    id: 0,
    variant: 'warning',
    name: 'Jane Hew',
    message: 'Hey! How is it going?',
    time: '9:32',
  },
  {
    id: 1,
    variant: 'success',
    name: 'Lloyd Brown',
    message: 'Check out my new Dashboard',
    time: '9:18',
  },
  {
    id: 2,
    variant: 'primary',
    name: 'Mark Winstein',
    message: 'I want rearrange the appointment',
    time: '9:15',
  },
  {
    id: 3,
    variant: 'secondary',
    name: 'Liana Dutti',
    message: 'Good news from sale department',
    time: '9:09',
  },
];

const storageKey = 'notificationLastDismissedAt';
const readNotificationLastDismissed = () => {
  const stored = localStorage.getItem(storageKey);
  if (!stored) {
    return moment().subtract(6, 'months').format('Y-MM-DD');
  }
  return stored;
};

const updatedNotificationLastDismissed = () => {
  localStorage.setItem(storageKey, moment().format('Y-MM-DD'));
};

export default function Header(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();
  const { user, isAuthenticated, accessToken } = useUserState();
  // local
  const [mailMenu, setMailMenu] = useState(null);
  const [isMailsUnread, setIsMailsUnread] = useState(true);
  const [notifications, setNotifications] = useState([]);
  // The reson this is not a boolean is because it sets to an event target on open
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  const [isNotificationsUnread, setIsNotificationsUnread] = useState(false);
  const [profileMenu, setProfileMenu] = useState(null);
  const [isSearchOpen, setSearchOpen] = useState(false);

  // TODO implement user types
  const isAdmin = false;

  useEffect(() => {
    const date = {
      end: moment().format('Y-MM-DD'),
      start: readNotificationLastDismissed(),
    };
    API.getHistoryBrands(accessToken, date)
      .then((r) => {
        const { data } = r;
        const { added, deleted, updated } = data;
        const addedBrands = added.length;
        const updatedBrands = updated.length;
        const deletedBrands = deleted.length;
        const totalBrands = addedBrands + updatedBrands + deletedBrands;
        if (totalBrands > 0) {
          setNotifications([
            {
              id: 1,
              color: 'secondary',
              type: 'offer', // src/components/Notification/Notification.js
              message: `Since ${moment(date.start).format('MMMM d')}, we have updated ${totalBrands} brands`,
            },
          ]);
          setIsNotificationsUnread(true);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img src={logo} height={30} alt="Good on You - Logo" style={{ marginLeft: 10 }} />
        <div className={classes.grow} />
        <a href="https://www.goodonyou.eco" target="_blank" rel="noreferrer" style={{ marginLeft: 100 }}>
          <img src={wordmark} height={30} alt="Good on You - Wordmark" />
        </a>
        <div className={classes.grow} />
        {isAdmin && (
          <>
            <div
              className={classNames(classes.search, {
                [classes.searchFocused]: isSearchOpen,
              })}
            >
              <div
                className={classNames(classes.searchIcon, {
                  [classes.searchIconOpened]: isSearchOpen,
                })}
                onClick={() => setSearchOpen(!isSearchOpen)}
              >
                <SearchIcon classes={{ root: classes.headerIcon }} />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            <IconButton
              color="inherit"
              aria-haspopup="true"
              aria-controls="mail-menu"
              onClick={(e) => {
                setMailMenu(e.currentTarget);
                setIsMailsUnread(false);
              }}
              className={classes.headerMenuButton}
            >
              <Badge
                badgeContent={isMailsUnread ? messages.length : null}
                color="secondary"
              >
                <MailIcon classes={{ root: classes.headerIcon }} />
              </Badge>
            </IconButton>
          </>
        )}
        {user && (
          <>
            <IconButton
              color="inherit"
              aria-haspopup="true"
              aria-controls="mail-menu"
              onClick={(e) => {
                setNotificationsMenu(e.currentTarget);
              }}
              className={classes.headerMenuButton}
            >
              <Badge
                badgeContent={isNotificationsUnread ? notifications.length : null}
                color="warning"
              >
                <NotificationsIcon classes={{ root: classes.headerIcon }} />
              </Badge>
            </IconButton>
            <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <AccountIcon classes={{ root: classes.headerIcon }} />
            </IconButton>
            <Menu
              id="mail-menu"
              open={Boolean(mailMenu)}
              anchorEl={mailMenu}
              onClose={() => setMailMenu(null)}
              MenuListProps={{ className: classes.headerMenuList }}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
            >
              <div className={classes.profileMenuUser}>
                <Typography variant="h4" weight="medium">
                  New Messages
                </Typography>
                <Typography
                  className={classes.profileMenuLink}
                  component="a"
                  color="secondary"
                >
                  {messages.length}
                  {' '}
                  New Messages
                </Typography>
              </div>
              {messages.map((message) => (
                <MenuItem key={message.id} className={classes.messageNotification}>
                  <div className={classes.messageNotificationSide}>
                    <UserAvatar color={message.variant} name={message.name} />
                    <Typography size="sm" color="text" colorBrightness="secondary">
                      {message.time}
                    </Typography>
                  </div>
                  <div
                    className={classNames(
                      classes.messageNotificationSide,
                      classes.messageNotificationBodySide,
                    )}
                  >
                    <Typography weight="medium" gutterBottom>
                      {message.name}
                    </Typography>
                    <Typography color="text" colorBrightness="secondary">
                      {message.message}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
              <Fab
                variant="extended"
                color="primary"
                aria-label="Add"
                className={classes.sendMessageButton}
              >
                Send New Message
                <SendIcon className={classes.sendButtonIcon} />
              </Fab>
            </Menu>
            <Menu
              id="notifications-menu"
              open={Boolean(notificationsMenu)}
              anchorEl={notificationsMenu}
              onClose={() => setNotificationsMenu(null)}
              className={classes.headerMenu}
              disableAutoFocusItem
            >
              {notifications.map((notification) => (
                <MenuItem
                  key={notification.id}
                  onClick={() => {
                    setIsNotificationsUnread(false);
                    updatedNotificationLastDismissed();
                  }}
                  className={classes.headerMenuItem}
                >
                  <Notification {...notification} typographyVariant="inherit" />
                </MenuItem>
              ))}
            </Menu>
            <Menu
              id="profile-menu"
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
            >
              <div className={classes.profileMenuUser}>
                <Typography variant="h4" weight="medium">
                  {user.email}
                </Typography>
                <Typography
                  color="primary"
                >
                  {user.company}
                </Typography>
              </div>
              {/* <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} />
              {' '}
              Profile
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} />
              {' '}
              Tasks
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} />
              {' '}
              Messages
            </MenuItem> */}
              <div className={classes.profileMenuUser}>
                <Typography
                  className={classes.profileMenuLink}
                  color="primary"
                  onClick={() => signOut(userDispatch, props.history)}
                >
                  Sign Out
                </Typography>
              </div>
            </Menu>
          </>
        )}
        {isAuthenticated && (
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}
