import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  capitalize,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  pullBack: {
    padding: '16px 16px 16px 0',
  },
}));

export default function HistoryTable({
  ratings, historyBrand,
}) {
  const classes = useStyles();
  const {
    label: overallLabel,
    headlines,
  } = ratings;
  const {
    label, animal, environment, labour,
  } = historyBrand;

  const allRatings = [{
    name: 'Environment',
    previous: environment,
    now: headlines.find((hl) => hl.name === 'environment').label,
  }, {
    name: 'Labour',
    previous: labour,
    now: headlines.find((hl) => hl.name === 'labour').label,
  }, {
    name: 'Animal',
    previous: animal,
    now: headlines.find((hl) => hl.name === 'animal').label,
  }];
  return (
    <Table className="mb-0">
      <TableBody>
        <TableRow>
          <TableCell />
          <TableCell>
            Previous
          </TableCell>
          <TableCell>
            Now
          </TableCell>
        </TableRow>
        <TableRow key="or">
          <TableCell className={`pl-3 fw-normal ${classes.pullBack}`}>Overall Score</TableCell>
          <TableCell>
            {label}
          </TableCell>
          <TableCell>
            {overallLabel}
            {'  '}
          </TableCell>
        </TableRow>
        {allRatings.map((headline) => {
          const {
            name,
            previous,
            now,
          } = headline;
          return (
            <TableRow key={name}>
              <TableCell className={`pl-3 fw-normal ${classes.pullBack}`}>{capitalize(name)}</TableCell>
              <TableCell>
                {capitalize(previous)}
              </TableCell>
              <TableCell>
                {capitalize(now)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

HistoryTable.propTypes = {
  ratings: PropTypes.shape({
    headlines: PropTypes.shape([]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  historyBrand: PropTypes.shape({
    label: PropTypes.string.isRequired,
    animal: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    labour: PropTypes.string.isRequired,
  }).isRequired,
};
