import React, { useEffect, useState } from 'react';

import {
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { BrandsAPIsSingleton as API } from '../../modules/Api';
import useStyles from './styles';
import { useUserState } from '../../context/UserContext';
import Widget from '../../components/Widget';
import PageTitle from '../../components/PageTitle';
import HistoryTable from './HistoryTable';

export default function HistoryBrand() {
  const classes = useStyles();

  const { accessToken } = useUserState();
  // local
  const [isLoading, setIsLoading] = useState(false);
  const [historyBrandData, setHistoryBrandData] = useState(null);
  const [selectedStartDate, handleStartDateChange] = useState(moment().format('Y-MM-DD'));
  const [selectedEndDate, handleEndDateChange] = useState(moment().subtract(1, 'months').format('Y-MM-DD')); // default gives last month changes
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const date = {
      end: moment(selectedStartDate).format('Y-MM-DD'),
      start: moment(selectedEndDate).format('Y-MM-DD'),
    };
    setIsLoading(true);
    API.getHistoryBrands(accessToken, date)
      .then((r) => {
        const { data } = r;
        setHistoryBrandData(data);
        setIsLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        setIsLoading(false);
      });
  }, [update]);

  return (
    <>
      <Helmet>
        <title>Good On You - History</title>
      </Helmet>
      <PageTitle
        title="History"
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle={false}
            disableWidgetMenu
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            <Grid container alignItems="baseline">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  inputVariant="outlined"
                  format="DD/MMM/yyyy"
                  minDate={new Date('2021/01/05')}
                  label="Start Date"
                  className={classes.datePicker}
                />
                <DatePicker
                  value={selectedStartDate}
                  onChange={handleStartDateChange}
                  inputVariant="outlined"
                  format="DD/MMM/yyyy"
                  minDate={new Date('2021/01/05')}
                  maxDate={new Date()}
                  label="End Date"
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
              <Button
                onClick={() => setUpdate(!update)}
                variant="outlined"
                className={classes.button}
              >
                {isLoading ? <CircularProgress color="secondary" size="20px" /> : (
                  ' Search '
                )}
              </Button>
            </Grid>
            {
              historyBrandData && <HistoryTable data={historyBrandData} />
            }
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
