import { VALUES, CERTIFICATIONS } from '../../modules/Constants';

export const splitTrim = (a) => a.toString().split(',').map((i) => i.trim());

export const arrayExists = (a) => a && Array.isArray(a) && a.length > 0;

export const prepareFilters = (data) => {
  const pushIfNotExist = (a, n) => a.indexOf(n) === -1 && a.push(n);
  const environmentFilters = [];
  const animalFilters = [];
  const labourFilters = [];
  const categoriesFilters = [];
  const segmentFilters = [];
  const valuesFilters = [];
  const certificationsFilters = [];
  data.forEach((d) => {
    pushIfNotExist(environmentFilters, d.environment.label);
    pushIfNotExist(animalFilters, d.animal.label);
    pushIfNotExist(labourFilters, d.labour.label);
    if (d.categories) {
      splitTrim(d.categories).forEach((x) => pushIfNotExist(categoriesFilters, x));
    }
    if (d.segment) {
      splitTrim(d.segment).forEach((x) => pushIfNotExist(segmentFilters, x));
    }
    if (arrayExists(d.values)) {
      d.values.forEach((id) => pushIfNotExist(valuesFilters, VALUES(id)));
    }
    if (arrayExists(d.certifications)) {
      d.certifications.forEach((id) => pushIfNotExist(certificationsFilters, CERTIFICATIONS(id)));
    }
  });
  return {
    environmentFilters,
    animalFilters,
    labourFilters,
    categoriesFilters,
    segmentFilters,
    valuesFilters,
    certificationsFilters,
  };
};

export const hasSomeFilters = (location, filters) => {
  // Location comes as a string because it's amended in the table renderer
  const a = splitTrim(location);
  if (!arrayExists(a)) {
    return true;
  }
  return !filters.some((v) => a.includes(v));
};

export const hasAllFilters = (location, filters) => {
  // Location comes as a string because it's amended in the table renderer
  const a = splitTrim(location);
  if (!arrayExists(a)) {
    return true;
  }
  return !filters.every((v) => a.includes(v));
};

export const sortLogicForRatings = (obj1, obj2, order) => {
  const convert = (o) => {
    if (o && o.data && o.data.score) {
      return o.data.score;
    }
    return 10;
  };
  const val1 = convert(obj1);
  const val2 = convert(obj2);
  return (val1 - val2) * (order === 'asc' ? 1 : -1);
};

export const sortLogicForArrays = (obj1, obj2, order, converter = null) => {
  const convert = (o) => {
    if (arrayExists(o.data)) {
      // We just need the first element to sort
      if (converter) {
        return converter(o.data[0], true);
      }
      return o.data[0];
    }
    return 'zzzzzzzz';
  };
  const val1 = convert(obj1);
  const val2 = convert(obj2);
  return val1.localeCompare(val2) * (order === 'asc' ? 1 : -1);
};
