export const VALUES = (id, abbreviated = false) => {
  switch (id) {
    case 'fairtrade':
      return 'Fairtrade';
    case 'give-back':
      return 'Give Back';
    case 'organic':
      return 'Organic';
    case 'vegan':
      return 'Vegan';
    case 'recycled':
      if (abbreviated) {
        return 'Recycled';
      }
      return 'Recycled / Upcycled materials';
    case 'eco-friendly':
      if (abbreviated) {
        return 'Eco-friendly';
      }
      return 'Eco-friendly materials';
    default:
      return null;
  }
};

export const CERTIFICATIONS = (id, abbreviated = false) => {
  switch (id) {
    case 'b-corp':
      if (abbreviated) {
        return 'B Corp';
      }
      return 'Certified B Corporation';
    case 'gots':
      if (abbreviated) {
        return 'GOTS';
      }
      return 'Global Organic Textile Standard';
    case 'grs':
      if (abbreviated) {
        return 'GRS';
      }
      return 'Global Recycling Standard';
    case 'bluesign':
      if (abbreviated) {
        return 'bluesign';
      }
      return 'bluesign certified';
    case 'oeko-tex':
      if (abbreviated) {
        return 'Standard 100';
      }
      return 'OEKO-TEX Standard 100';
    case 'fairtrade-c':
      if (abbreviated) {
        return 'Fairtrade';
      }
      return 'Fairtrade certified';
    case 'fairwear':
      if (abbreviated) {
        return 'Fair Wear';
      }
      return 'Fair Wear Foundation';
    case 'peta':
      if (abbreviated) {
        return 'PETA';
      }
      return 'PETA-Approved Vegan';
    default:
      return null;
  }
};
