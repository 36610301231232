import React from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip,
  capitalize,
  Link
} from "@material-ui/core";
import RatingsDots from '../../../components/RatingsDots';
import PricingDots from '../../../components/PricingDots';

// import useStyles from './styles';

export default function SummaryTable({ brand, displayFarFetch }) {
  const {
    size,
    categories,
    price,
    segment,
    contact,
    ratings,
    farfetch,
    parent_company: parentCompany,
    sku,
  } = brand;
  const { name: contactName, email } = contact;
  const { label, dots } = ratings;
  return (
    <Table className="mb-0">
      <TableBody>
        <TableRow key="or">
          <TableCell className="pl-3 fw-normal">Overall Rating</TableCell>
          <TableCell>
            {label}
            {'  '}
            <RatingsDots value={dots} />
          </TableCell>
        </TableRow>
        <TableRow key="si">
          <TableCell className="pl-3 fw-normal">Size</TableCell>
          <TableCell>{size && capitalize(size)}</TableCell>
        </TableRow>
        <TableRow key="se">
          <TableCell className="pl-3 fw-normal">Segment</TableCell>
          <TableCell>{segment && segment.join(', ')}</TableCell>
        </TableRow>
        <TableRow key="ca">
          <TableCell className="pl-3 fw-normal">Categories</TableCell>
          <TableCell>{categories && categories.join(', ')}</TableCell>
        </TableRow>
        <TableRow key="pr">
          <TableCell className="pl-3 fw-normal">Price</TableCell>
          <TableCell><PricingDots value={price} /></TableCell>
        </TableRow>
        {displayFarFetch && (
          <TableRow key="pr">
            <TableCell className="pl-3 fw-normal">Farfetch</TableCell>
            <TableCell>{farfetch ? 'Yes' : 'No'}</TableCell>
          </TableRow>
        )}
        <TableRow key="pr">
          <TableCell className="pl-3 fw-normal">Parent Company</TableCell>
          <TableCell>{parentCompany || 'No'}</TableCell>
        </TableRow>
        <TableRow key="co">
          <TableCell className="pl-3 fw-normal">Contact:</TableCell>
          <TableCell>{email ? <Link href={`mailto:${email}`}>{email}</Link> : 'N/A'}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
