import React, { useEffect, useState } from 'react';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { BrandsAPIsSingleton as API } from '../../modules/Api';

import useStyles from './styles';
import { useUserDispatch, signOut, useUserState } from '../../context/UserContext';
import Widget from '../../components/Widget';
import PageTitle from '../../components/PageTitle';
import BrandsTable from './BrandsTable';
import { can } from '../../modules/Authorisation';
import { Helmet } from 'react-helmet';

export default function Dashboard({ history }) {
  const classes = useStyles();
  const theme = useTheme();
  const userDispatch = useUserDispatch();
  const { user, accessToken } = useUserState();
  const displayFarFetch = can(user, 'seeFarfetch');
  const displayCertifications = can(user, 'seeCertifications');
  // local
  const [brands, setBrands] = useState(null);
  useEffect(() => {
    API.readAllBrands(accessToken)
      .then((r) => {
        const { data } = r;
        setBrands(data.brands);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
          signOut(userDispatch, history);
        }
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>Good On You - Dashboard</title>
      </Helmet>
      <PageTitle
        title="Rated Brands"
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle={false}
            disableWidgetMenu
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            { brands ? (
              <BrandsTable data={brands} displayFarFetch={displayFarFetch} displayCertifications={displayCertifications} />
            ) : (
              <CircularProgress size={26} className={classes.loginLoader} />
            )}
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
