import React from 'react';
import MUIDataTable from 'mui-datatables';
import { capitalize, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import slugify from 'slugify';
import pluralize from 'pluralize';
import moment from 'moment';

import PricingDots from '../../components/PricingDots';
import DotsField from './components/table/DotsField';
import RatingsField from './components/table/RatingsField';
import useStyles from './styles';
import {
  splitTrim, arrayExists, prepareFilters, hasAllFilters,
  sortLogicForArrays,
  sortLogicForRatings,
  hasSomeFilters,
} from './helpers';
import { CERTIFICATIONS, VALUES } from '../../modules/Constants';

const options = {
  downloadOptions: {
    filename: `goy-exported-${moment().format('DD-MMM-Y')}.csv`,
    separator: ',',
    filterOptions: {
      useDisplayedRowsOnly: true,
      useDisplayedColumnsOnly: true,
    },
  },
  selectableRows: 'none',
  searchOpen: 'true',
  print: false,
  rowsPerPageOptions: [10, 20, 50],
  onDownload: (buildHead, buildBody, columns, data) => (
    `\uFEFF${buildHead(columns)}${buildBody(data.map((d) => {
      const rd = JSON.parse(JSON.stringify(d));
      const rdd = rd.data.map((s) => {
        // We need to transform the nested object to strings
        // Datatables CSV export does not use the table transformers
        // Easily fixed as all nested object follow the same structure
        // { score: int, label: string }
        // TODO future proof it?
        if (s && typeof s === 'object' && s.label) {
          return s.label;
        }
        return s;
      });
      return { ...rd, data: rdd };
    }))}`
  ),
};

export default function BrandsTable({ data, displayFarFetch, displayCertifications }) {
  const classes = useStyles();
  const {
    environmentFilters,
    animalFilters,
    labourFilters,
    categoriesFilters,
    segmentFilters,
    valuesFilters,
    certificationsFilters,
  } = prepareFilters(data);
  return (
    <MUIDataTable
      title={false}
      data={data}
      columns={[
        {
          name: 'id',
          label: 'ID',
          options: {
            searchable: false,
            filter: false,
            display: 'excluded',
          },
        },
        {
          name: 'name',
          label: 'Name',
          options: {
            filter: false,
            searchable: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              const { rowData } = tableMeta;
              const id = rowData[0];
              return (
                <Link
                  component={RouterLink}
                  to={`/brand/${slugify(value, { lower: true })}-${id}`}
                  color="primary"
                  className={classes.link}
                >
                  { value }
                </Link>
              );
            },
          },
        },
        {
          name: 'headquarters',
          label: 'Headquarters',
          options: {
            filter: true,
            searchable: true,
            sortDescFirst: true,
          },
        },
        {
          name: 'website',
          label: 'Website',
          options: {
            searchable: false,
            filter: false,
            display: false,
          },
        },
        {
          name: 'dots',
          label: 'Overall Rating',
          options: {
            searchable: false,
            customBodyRender: (value, tableMeta, updateValue) => (
              <DotsField
                value={value}
                updateValue={updateValue}
              />
            ),
            filterOptions: {
              renderValue: (v) => '⬤'.repeat(v),
            },
            customFilterListOptions: {
              render: (v) => `Rating: ${'⬤'.repeat(v)}   `,
            },
          },
        },
        {
          name: 'score',
          label: 'Overall Score',
          options: {
            searchable: false,
            filter: false,
            display: false,
          },
        },
        {
          name: 'environment',
          label: 'Environment',
          options: {
            searchable: false,
            sort: true,
            sortDescFirst: true,
            sortCompare: (order) => (
              (obj1, obj2) => (
                sortLogicForRatings(obj1, obj2, order)
              )
            ),
            customBodyRender: (value, tableMeta, updateValue) => (
              <RatingsField
                value={value}
                updateValue={updateValue}
              />
            ),
            filterOptions: {
              names: environmentFilters,
              logic: (location, filters, row) => {
                if (location && location.label) {
                  return !(filters[0] === location.label);
                }
                return false;
              },
            },
            customFilterListOptions: {
              render: (v) => `Environment: ${v}`,
            },
          },
        },
        {
          name: 'labour',
          label: 'Labour',
          options: {
            searchable: false,
            sort: true,
            sortDescFirst: true,
            sortCompare: (order) => (
              (obj1, obj2) => (
                sortLogicForRatings(obj1, obj2, order)
              )
            ),
            customBodyRender: (value, tableMeta, updateValue) => (
              <RatingsField
                value={value}
                updateValue={updateValue}
              />
            ),
            filterOptions: {
              names: labourFilters,
              logic: (location, filters, row) => {
                if (location && location.label) {
                  return !(filters[0] === location.label);
                }
                return false;
              },
            },
            customFilterListOptions: {
              render: (v) => `Labour: ${v}`,
            },
          },
        },
        {
          name: 'animal',
          label: 'Animal',
          options: {
            searchable: false,
            sort: true,
            sortDescFirst: true,
            sortCompare: (order) => (
              (obj1, obj2) => (
                sortLogicForRatings(obj1, obj2, order)
              )
            ),
            customBodyRender: (value, tableMeta, updateValue) => (
              <RatingsField
                value={value}
                updateValue={updateValue}
              />
            ),
            filterOptions: {
              names: animalFilters,
              logic: (location, filters, row) => {
                if (location && location.label) {
                  return !(filters[0] === location.label);
                }
                return false;
              },
            },
            customFilterListOptions: {
              render: (v) => `Animal: ${v}`,
            },
          },
        },
        {
          name: 'values',
          label: 'Values',
          options: {
            searchable: false,
            sort: true,
            sortDescFirst: false,
            sortCompare: (order) => (
              (obj1, obj2) => (
                sortLogicForArrays(obj1, obj2, order, VALUES)
              )
            ),
            display: displayCertifications ? false : 'excluded',
            filter: displayCertifications,
            download: displayCertifications,
            customBodyRender: (value, tableMeta, updateValue) => {
              if (arrayExists(value)) {
                return value.map((id) => VALUES(id, true)).join(', ');
              }
              return null;
            },
            filterType: 'multiselect',
            filterOptions: {
              names: valuesFilters,
              logic: (location, filters, row) => {
                // If location is empty, filter out!
                if (!location) {
                  return true;
                }
                // We need the RAW data from the row, then transform it to the label which the
                // filters are using
                if (!arrayExists(row[9])) {
                  return true;
                }
                const csc = row[9].map((id) => VALUES(id)).join(',');
                if (csc && arrayExists(filters)) {
                  return hasAllFilters(csc, filters);
                }
                return false;
              },
            },
          },
        },
        {
          name: 'certifications',
          label: 'Certifications',
          options: {
            searchable: false,
            sort: true,
            sortDescFirst: false,
            sortCompare: (order) => (
              (obj1, obj2) => (
                sortLogicForArrays(obj1, obj2, order, CERTIFICATIONS)
              )
            ),
            display: displayCertifications ? false : 'excluded',
            filter: displayCertifications,
            download: displayCertifications,
            customBodyRender: (value, tableMeta, updateValue) => {
              if (arrayExists(value)) {
                return value.map((id) => CERTIFICATIONS(id, true)).join(', ');
              }
              return null;
            },
            filterType: 'multiselect',
            filterOptions: {
              names: certificationsFilters,
              logic: (location, filters, row) => {
                // If location is empty, filter out!
                if (!location) {
                  return true;
                }
                // We need the RAW data from the row, then transform it to the label which the
                // filters are using
                if (!arrayExists(row[10])) {
                  return true;
                }
                const csc = row[10].map((id) => CERTIFICATIONS(id)).join(',');
                if (csc && arrayExists(filters)) {
                  return hasAllFilters(csc, filters);
                }
                return false;
              },
            },
          },
        },
        {
          name: 'categories',
          label: 'Categories',
          options: {
            searchable: false,
            sort: true,
            sortDescFirst: false,
            sortCompare: (order) => (
              (obj1, obj2) => (
                sortLogicForArrays(obj1, obj2, order)
              )
            ),
            filterType: 'multiselect',
            display: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              if (arrayExists(value)) {
                return value.join(', ');
              }
              return null;
            },
            filterOptions: {
              names: categoriesFilters,
              logic: (location, filters, row) => {
                // If location is empty, filter out!
                if (!location) {
                  return true;
                }
                if (location && arrayExists(filters)) {
                  return hasSomeFilters(location, filters);
                }
                return false;
              },
            },
          },
        },
        {
          name: 'price',
          label: 'Price',
          options: {
            searchable: false,
            customBodyRender: (value, tableMeta, updateValue) => (
              <PricingDots
                value={value}
                updateValue={updateValue}
              />
            ),
            filter: true,
            filterOptions: {
              renderValue: (v) => '$'.repeat(v),
            },
            customFilterListOptions: {
              render: (v) => '$'.repeat(v),
            },
          },
        },
        {
          name: 'size',
          label: 'Size',
          options: {
            searchable: false,
            filter: false,
            display: false,
            sortDescFirst: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              if (value) {
                return capitalize(value);
              }
              return null;
            },
          },
        },
        {
          name: 'segment',
          label: 'Segment',
          options: {
            sort: true,
            sortDescFirst: false,
            sortCompare: (order) => (
              (obj1, obj2) => (
                sortLogicForArrays(obj1, obj2, order)
              )
            ),
            customBodyRender: (value, tableMeta, updateValue) => {
              if (arrayExists(value)) {
                return value.join(', ');
              }
              return null;
            },
            filterOptions: {
              names: segmentFilters,
              logic: (location, filters, row) => {
                if (location) {
                  return !splitTrim(location).some((r) => filters.indexOf(r) >= 0);
                }
                return false;
              },
            },
          },
        },
        {
          name: 'last_updated',
          label: 'Last updated',
          options: {
            searchable: false,
            filter: true,
            display: false,
            sortDescFirst: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return moment(value).format('D MMM Y')
            },
            sortCompare: (order) => (
              (obj1, obj2) => {
                return (new Date(obj1.data) - new Date(obj2.data)) * (order === 'asc' ? 1 : -1);
              }
            ),
            filterOptions: {
              names: [2021, 2020, 2019, '2018 and before'],
              logic: (location, filters, row) => {
                if (location) {
                  const parsedDate = moment(location, 'D MMM Y');
                  if (filters[0] === '2018 and before') {
                    return !parsedDate.isBefore(moment('2018 12 31'));
                  }
                  return !parsedDate
                    .isBetween(moment(`${filters[0]} 01 01`), moment(`${filters[0]} 12 31`),);
                }
                return false;
              },
            },
          },
        },
        {
          name: 'farfetch',
          label: 'Farfetch',
          options: {
            display: displayFarFetch ? true : 'excluded',
            sort: true,
            sortDescFirst: true,
            filter: displayFarFetch,
            download: displayFarFetch,
            filterOptions: {
            },
          },
        },
      ]}
      options={options}
    />
  );
}
