import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  button: {
    height: 55,
    backgroundColor: '#222',
    color: 'white',
    alignSelf: 'flex-end',
    marginBottom: 8,
    '&:hover': {
      backgroundColor: '#222',
    },
  },
  datePicker: {
    padding: '.5rem',
    marginTop: '1rem',
  },
  new: {
    color: theme.palette.success.main,
  },
  updated: {
    color: theme.palette.warning.main,
  },
  removed: {
    color: '#d7bfc5',
  },

  bold: {
    fontWeight: 500,
  },

  arrowUPWARDS: {
    fontSize: 14,
    color: 'green',
  },
  arrowDOWNWARDS: {
    fontSize: 14,
    color: 'red',
  },
}));
