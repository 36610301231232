const ratings = [
  'Not applicable',
  'Not assessed',
  'We avoid',
  'Very poor',
  'Not good enough',
  'It\'s a start',
  'Good',
  'Great',
];

const dots = [
  'We avoid',
  'Not good enough',
  'It\'s a start',
  'Good',
  'Great',

];

export const calculateRatingDifference = (prevRating, newRating) => {
  if (prevRating === newRating || prevRating === undefined) { return ''; }

  if (ratings.indexOf(prevRating) > ratings.indexOf(newRating)) return 'DOWNWARDS';
  if (ratings.indexOf(prevRating) < ratings.indexOf(newRating)) return 'UPWARDS';
  return null;
};

export const getDots = (rating) => dots.indexOf(rating) + 1;
