import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  black: {
    color: theme.palette.common.black,
  },
  grey: {
    color: theme.palette.placeholder,
  },
}));

export default function RatingsDots({ value }) {
  const classes = useStyles();
  const ceiling = 5;
  return (
    <>
      <span className={classes.black}>{'⬤'.repeat(value)}</span>
      <span className={classes.grey}>{'⬤'.repeat(ceiling - value)}</span>
    </>
  );
}
