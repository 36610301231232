import React from 'react';

// styles
import useStyles from './styles';

// components
import { Typography } from '../Wrappers';

export default function PageTitle(props) {
  const { title, button, backgroundImage } = props;
  const classes = useStyles();
  let additionalStyle = {};
  if (backgroundImage) {
    additionalStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight: 200,
    };
  }
  return (
    <div className={classes.pageTitleContainer} style={additionalStyle}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {title}
      </Typography>
      {button && button}
    </div>
  );
}
