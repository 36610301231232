import React from 'react';
import {
  Grid,
  Container,
  Link,
  Breadcrumbs,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Search } from '@material-ui/icons';

import useStyles from './styles';
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Typography } from '../../components/Wrappers/Wrappers';

export default function Dashboard({ match }) {
  const classes = useStyles();
  return (
    <Container fixed>
      <Helmet>
        <title>Good On You - Help</title>
      </Helmet>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RouterLink}
          to="/"
          color="primary"
          className={classes.link}
        >
          Home
        </Link>
      </Breadcrumbs>
      <PageTitle
        title="Help"
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget title="Search" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text}>
                Click the search icon
                {' '}
                <Search />
                {' '}
                and type a brand name to search for rated brands.
              </Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Sort" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text}>
                You can sort the results in the table by clicking on any of the headers eg Name, Overall Rating etc
              </Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Filters" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text}>
                Click the filter icon
                {' '}
                <FilterListIcon />
                {' '}
                to filter brands by any field.
              </Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Export" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text}>
                Choose the download icon
                {' '}
                <CloudDownloadIcon />
                {' '}
                to export brands in CSV format.
                <br />
                Note that in the CSV export the ‘Price’ data and the ‘Ratings’ data is converted to a number
                - eg the price $$ is shown as ‘2’ and the Overall rating “Good” is shown as “4”.
              </Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Ratings" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text}>
                For information on how Good On You rates brands see the
                {' '}
                <Link href="https://goodonyou.eco/how-we-rate" target="_blank">guide to the Good on You rating system</Link>
              </Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget title="Feedback" disableWidgetMenu>
            <div className={classes.dashedBorder}>
              <Typography className={classes.text}>
                For more support, contact us at
                {' '}
                <a href="info@goodonyou.eco">info@goodonyou.eco</a>
              </Typography>
            </div>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
