import { createMuiTheme } from '@material-ui/core';
import defaultTheme from './default';

const overrides = {
  typography: {
    fontFamily: "'Apercu', Helvetica, Arial, sans-serif;",
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.64rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.285rem',
    },
    h6: {
      fontSize: '1.142rem',
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      toolButton: {
        fontWeight: 'bold',
        color: '#222222',
        textDecoration: 'underline',
        fontSize: '1.01em',
      },
      sortLabelRoot: {
        height: '100%',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
        color: '#222222',
        fontSize: '1.01em',
      },
    },
  },
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};
