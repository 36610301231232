import React from 'react';
import RatingsDots from '../../../../components/RatingsDots';

export default function DotsField({ value }) {
  let label = null;
  switch (value) {
    case 1:
      label = 'We avoid';
      break;
    case 2:
      label = 'Not good enough';
      break;
    case 3:
      label = 'It\' s a start';
      break;
    case 4:
      label = 'Good';
      break;
    case 5:
      label = 'Great';
      break;
    default:
      label = '?';
      break;
  }
  return (
    <>
      <RatingsDots value={value} />
      <br />
      { label }
    </>
  );
}
