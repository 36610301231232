import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useRouteMatch,
} from 'react-router-dom';
import classnames from 'classnames';
import { Box, IconButton, Link } from '@material-ui/core';
import Icon from '@mdi/react';
import { can } from '../../modules/Authorisation';
// icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js';

// styles
import useStyles from './styles';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';

// pages
import Dashboard from '../../pages/dashboard';
import HistoryBrand from '../../pages/history/HistoryBrand';
import Typography from '../../pages/typography';
import Notifications from '../../pages/notifications';
import Maps from '../../pages/maps';
import Tables from '../../pages/tables';
import Icons from '../../pages/icons';
import Charts from '../../pages/charts';
import Brand from '../../pages/brand';
import Help from '../../pages/help';

// context
import { useLayoutState } from '../../context/LayoutContext';
import { useUserState } from '../../context/UserContext';
import Login from '../../pages/login/Login';

function Layout(props) {
  const classes = useStyles();

  // global
  const layoutState = useLayoutState();

  const { isAuthenticated, user } = useUserState();
  const match = useRouteMatch({
    path: '/app/dashboard',
    strict: false,
    sensitive: false,
  });

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        {isAuthenticated && <Sidebar />}
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          {isAuthenticated && (
            <div style={match ? {} : { display: 'none' }}>
              <Dashboard />
            </div>
          )}
          <Switch>
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/brand/:id" component={Brand} />
            {can(user, 'seeHistory') && <Route path="/app/history" component={HistoryBrand} />}
            <Route path="/help" component={Help} />
            <Route path="/login" component={Login} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
          </Switch>
          <Box
            mt={5}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <div>
              <Link
                color="primary"
                href="https://www.goodonyou.eco/"
                target="_blank"
                className={classes.link}
              >
                Blog
              </Link>
              <Link
                color="primary"
                href="https://directory.goodonyou.eco"
                target="_blank"
                className={classes.link}
              >
                Directory
              </Link>
            </div> */}
          </Box>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
