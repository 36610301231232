export const LOG_LEVEL_ERROR = 'ERROR';

export const logWarn = (message, context = null) => {
  let warning = `[warn] ${message}`;
  if (context) {
    warning = `[${context}] ${warning}`;
  }
  // eslint-disable-next-line no-console
  console.warn(warning);
};

export const logInfo = (message, context = null) => {
  let infoString = `[info] ${message}`;
  if (context) {
    infoString = `[${context}] ${infoString}`;
  }
  // eslint-disable-next-line no-console
  console.info(infoString);
};

export const logError = (message, context = null) => {
  let errorString = `[error] ${message}`;
  if (context) {
    errorString = `[${context}] ${errorString}`;
  }
  // eslint-disable-next-line no-console
  console.warn(errorString);
};
