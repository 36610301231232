import React from 'react';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  capitalize,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CERTIFICATIONS, VALUES } from '../../../modules/Constants';
import RatingsDots from '../../../components/RatingsDots';

const useStyles = makeStyles(() => ({
  pullBack: {
    padding: '16px 16px 16px 0',
  },
}));

export default function RatingsTable({
  ratings, values, certifications, displayCertifications,
}) {
  const classes = useStyles();
  const {
    score: overallScore,
    max_score: overallMaxScore,
    label: overallLabel,
    dots: overallDots,
    headlines,
  } = ratings;
  return (
    <Table className="mb-0">
      <TableBody>
        <TableRow key="or">
          <TableCell className={`pl-3 fw-normal ${classes.pullBack}`}>Overall Score</TableCell>
          <TableCell>
            {`${overallScore} / ${overallMaxScore}`}
          </TableCell>
          <TableCell>
            {overallLabel}
            {'  '}
            <RatingsDots value={overallDots} />
          </TableCell>
        </TableRow>
        {headlines.map((headline) => {
          const {
            name,
            score,
            max_score: maxScore,
            label,
          } = headline;
          return (
            <TableRow key={name}>
              <TableCell className={`pl-3 fw-normal ${classes.pullBack}`}>{capitalize(name)}</TableCell>
              <TableCell>
                {`${score} / ${maxScore}`}
              </TableCell>
              <TableCell>
                {capitalize(label)}
              </TableCell>
            </TableRow>
          );
        })}
        {displayCertifications && values && (
          <TableRow key="values">
            <TableCell className={`pl-3 fw-normal ${classes.pullBack}`}>Values</TableCell>
            <TableCell colSpan={2}>
              {values.map((id) => VALUES(id)).join(', ')}
            </TableCell>
          </TableRow>
        )}
        {displayCertifications && certifications && (
          <TableRow key="certifications">
            <TableCell className={`pl-3 fw-normal ${classes.pullBack}`}>Certifications</TableCell>
            <TableCell colSpan={2}>
              {certifications.map((id) => CERTIFICATIONS(id)).join(', ')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
