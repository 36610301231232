import AuthAPIs from './Auth';
import getConfigForEnvironment from '../../config/getConfigForEnvironment';
import BrandsAPIs from './Brands';

const AuthAPIsSingleton = new AuthAPIs(getConfigForEnvironment());
const BrandsAPIsSingleton = new BrandsAPIs(getConfigForEnvironment());

export {
  AuthAPIsSingleton,
  BrandsAPIsSingleton,
};
