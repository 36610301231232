import React, { useEffect, useState } from 'react';

import {
  Grid,
  CircularProgress,
  Avatar,
  Container,
  Link,
  Breadcrumbs,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

import { BrandsAPIsSingleton as API } from '../../modules/Api';

import useStyles from './styles';
import { useUserState } from '../../context/UserContext';
import Widget from '../../components/Widget';
import SummaryTable from './components/SummaryTable';
import HistoryTable from './components/HistoryTable';
import RatingsTable from './components/RatingsTable';
import { Typography } from '../../components/Wrappers';
import { can } from '../../modules/Authorisation';

export default function Dashboard({ match, history }) {
  const classes = useStyles();
  const { id } = match.params;

  const { user, accessToken } = useUserState();
  const displayFarFetch = can(user, 'seeFarfetch');
  const displayCertifications = can(user, 'seeCertifications');
  // local
  const [brand, setBrand] = useState(null);
  const [historyBrand, setHistoryBrand] = useState(null);

  useEffect(() => {
    const numericId = id.split('-').pop();
    API.readOneBrand(accessToken, numericId)
      .then((r) => {
        const { data } = r;
        setBrand(data);
      })
      .catch((e) => {
        if (e && e.message && e.message === 'LOGOUT') {
        // signOut(userDispatch, history);
        }
      });
    const date = {
      start: '01/05/2021',
      end: moment().format('DD/MM/Y'),
    };
    API.getHistoryBrands(accessToken, date)
      .then((r) => {
        const { data } = r;
        const previousRating = data.updated
          .find((brandH) => brandH.id === Number(numericId)).prev_rating;
        if (previousRating) {
          setHistoryBrand({
            lastUpdated: moment(previousRating.last_updated).format('D MMMM Y'),
            ...previousRating,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id]);
  if (!brand) {
    return <CircularProgress size={26} />;
  }
  const {
    name,
    headquarters,
    website,
    sentence,
    logo,
    summary,
    ratings,
    cover,
    last_updated: updatedAt,
    values,
    certifications,
  } = brand;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RouterLink}
          to="/app/dashboard"
          color="primary"
          className={classes.link}
        >
          Back
        </Link>
      </Breadcrumbs>
      <div style={{
        position: 'absolute',
        top: 60,
        left: 0,
        width: '100%',
        height: 200,
        zIndex: -1,
      }}
      >
        <img
          src={cover}
          style={{
            objectFit: 'cover',
            objectPosition: '100% 0',
            width: '100%',
            height: 300,
          }}
          alt="Brand cover"
        />
      </div>
      <Container fixed style={{ marginTop: 200 }}>
        <Helmet>
          <title>{`GOY - ${name} rating`}</title>
        </Helmet>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Widget title={false} disableWidgetMenu>
              <div className={classes.dashedBorder}>
                <Avatar alt="Brand Logo" src={logo} className={classes.avatar} />
                <Typography variant="h2" component="h2">
                  {name}
                </Typography>
                <Typography className={classes.brandOverviewParagraph} weight="medium">
                  {headquarters}
                  <br />
                  <br />
                  <Link href={website} target="_blank">{website}</Link>
                </Typography>
                <Typography className={classes.brandOverviewParagraph}>
                  {sentence}
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item xs={12} md={8}>
            <Widget title={false} disableWidgetMenu>
              <div className={classes.dashedBorder}>
                <SummaryTable brand={brand} displayFarFetch={displayFarFetch} />
              </div>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget title="Ratings" disableWidgetMenu>
              <div className={classes.dashedBorder}>
                <RatingsTable
                  ratings={ratings}
                  displayCertifications={displayCertifications}
                  values={values}
                  certifications={certifications}
                />
              </div>
            </Widget>
          </Grid>
          {historyBrand && (
          <Grid item xs={12}>
            <Widget title="History" disableWidgetMenu>
              <div className={classes.dashedBorder}>
                <HistoryTable
                  ratings={ratings}
                  historyBrand={historyBrand}
                />
              </div>
              <Grid xs={10} className={classes.lastUpdatedHistory}>
                <Typography variant="caption">
                  Last Updated:
                  {' '}
                  {historyBrand.lastUpdated}
                </Typography>
              </Grid>
            </Widget>
          </Grid>
          )}
          <Grid item xs={12}>
            <Widget title="Details" disableWidgetMenu>
              {summary && (
                <div className={classes.summary} dangerouslySetInnerHTML={{ __html: summary }} />
              )}
            </Widget>
          </Grid>
          <Grid item xs={12} style={{ marginLeft: 21 }}>
            <Typography>
              {`Last updated: ${moment(updatedAt, 'YYYY-MM-DD').format('D MMMM Y')}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
