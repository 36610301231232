import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  black: {
    color: theme.palette.common.black,
  },
  grey: {
    color: theme.palette.placeholder,
  },
}));

// import useStyles from './styles';

export default function PricingDots({ value }) {
  const classes = useStyles();
  const ceiling = 4;
  return (
    <>
      <span className={classes.black}>{'$'.repeat(value)}</span>
      <span className={classes.grey}>{'$'.repeat(ceiling - value)}</span>
    </>
  );
}
