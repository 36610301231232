// eslint-disable-next-line import/prefer-default-export
export function can(user, action) {
  if (!user) {
    return false;
  }
  const companies = ['MBB', 'OTRIUM', 'YNAP', 'Microsoft', 'Klarna', 'FARFETCH', 'Shaftesbury', 'Updater', 'Afterpay', 'URW', 'Tribees Marketplace'];
  switch (action) {
    case 'seeFarfetch':
      return user.company === 'FARFETCH';
    case 'seeCertifications':
      // Check if user is in the companies array
      return companies.includes(user.company);
    case 'seeHistory':
      return companies.includes(user.company);
    default:
      return false;
  }
}
